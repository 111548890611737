import React, {useEffect} from 'react'
import HomeComponent from '../components/HomeComponent';

const Home = () => {
  
useEffect(()=>{
document.title = 'Benard Awuko - Portfolio Website'
},[]);

  return (
    <>
    <main className='main'>
      <HomeComponent></HomeComponent>
    </main>

    </>
  )
}

export default Home