import React,{useEffect} from 'react'

const CV = () => {
    useEffect(()=>{
document.title = 'CV - Benard Awuko'
},[])
  return (
    <section className='section CV-section'>
        <div className='container CV-container'>

            <iframe src="../docs/Benard-Awuko-Resume.pdf" className='cv-page'></iframe>


        </div>



    </section>
  )
}

export default CV