import React from 'react'
import {FaSmile } from "react-icons/fa";
import {FaPaperPlane } from "react-icons/fa";
import  {Link} from 'react-router-dom'
const Data = () => {
  return (
    <div className='home-data'>
        <h1 className='home-title'><p>Benard Awuko</p><span className='home-smile'><FaSmile></FaSmile></span></h1>
        <div className='title-cover'>
       <div className='my-line'></div> <h3 className='home-subtitle'> Front-End Engineer</h3><div className='my-line '></div>
        </div>
        <p className='home-description'>
I'm a Front-End Engineer with over 5 years of experience in developing websites and front-end web applications. I'm proficient in HTML, CSS, JavaScript, React JS, Git & Github. More so, I'm so passionate about problem-solving and innovation.
        </p>
         <Link to="contact" className='button button--flex hello-btn'>
         Say Hello<span className='hello-icon'><FaPaperPlane></FaPaperPlane></span>
         </Link>
    
    </div>
  )
}

export default Data