import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Skills from './pages/Skills';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Error from './pages/Error';
import Nav from "./components/Nav";
import Footer from './components/footer'
import CV from './pages/CV';
import BackToTop from "./components/BackToTop";
import WhatsAppIcon from "./components/WhatsAppIcon";

// import Frontend from "./pages/Frontend";


function App() {
   return (
    <BrowserRouter>
    <Nav></Nav>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='about' element={<About></About>}></Route>
        <Route path='skills' element={<Skills></Skills>}></Route>
        <Route path='services' element={<Services></Services>}></Route>
          <Route path='portfolio' element={<Portfolio></Portfolio>}></Route>
          {/* <Route path='portfolio/:frontend' element={<Frontend></Frontend>}></Route> */}
            <Route path='contact' element={<Contact></Contact>}></Route>
            <Route path='about/:cv' element={<CV></CV>}></Route>
              <Route path='*' element={<Error></Error>}></Route>
      </Routes>
      <Footer></Footer>
      <BackToTop></BackToTop>
      <WhatsAppIcon></WhatsAppIcon>
        
    </BrowserRouter>
  );
  
}

export default App;
