import React, { useState } from 'react';
// import Reviewers from './data';
import Reviewers from './Reviewers';
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from 'react-icons/fa';

const Review = () => {
  let [index, setIndex] = useState(0);
  let {name, job, image, text} = Reviewers[index];


let checkNum = (num)=>{
if (num < 0){
  return Reviewers.length - 1;
}

if (num > Reviewers.length - 1){
  return 0
}

else {
  return num;
}


}


let nextPerson = () =>{
setIndex((index)=>{
    let newIndex = index + 1;
    return checkNum(newIndex);
})
}

// setInterval(()=>{
// setIndex((index)=>{
//     let newIndex = index + 1;
//     return checkNum(newIndex);
// })

// }, 3000)


let prevPerson = () =>{
setIndex((index)=>{
    let newIndex = index + 1;
    return checkNum(newIndex);
})
}

// let randomPerson = () =>{
// let randomNum = Math.floor(Math.random() * Reviewers.length);

// if (randomNum === index){
//   randomNum = index + 1;
// }


// setIndex(checkNum(randomNum));

// }




  return (
   <main className='reviews-cover'> 
   <h2 className='section-title'>Testimonials</h2>
    <span className='section-subtitle section-subtitle1'>What People Say</span>
                 <section className='reviews-container'>
                      {/* <div className='title'>
                        <h2>Reviews</h2>
                        <div className='underline'></div>
                      </div> */}
  
  
  
  <article className='review'>
             <div className='img-container1'>
               <img src={image} alt={name} className="person-img"></img>
               <span className='quote-icon'>
                <FaQuoteRight></FaQuoteRight>
               </span>
              
              </div>
              <h4 className='author'>{name}</h4>
              <p className='job'>{job}</p>
              <p className='info'>{text}</p>
              <div className='buttons-container'>
                <button className='prev-btn' onClick={prevPerson}>
                    <FaChevronLeft></FaChevronLeft>      
                </button>
 <button className='next-btn' onClick={nextPerson}>
                    <FaChevronRight></FaChevronRight>      
                </button>

              </div>
                {/* <button className='random-btn' onClick={randomPerson}>
                 Suprise Me     
                </button> */}

         </article>
         
           </section>
        </main>)
};

export default Review;