import React, {useEffect} from 'react';
import Qualifications from '../components/Qualifications';
import {FaRegCheckCircle, FaCogs,FaGithub,FaUserTie} from 'react-icons/fa'

const Skills = () => {

useEffect(()=>{
document.title = 'Skills - Benard Awuko'
},[]);


  return (
    <>
    <section className='skills-section'>
      <h2 className='section-title'>My Skills</h2>
      <span className='section-subtitle section-subtitle1'>Technical & Professional Skills</span>
      <div className='skills-container'>
          <article className='skill-category'>
                    <div className='skill-top-icon'>
                      <FaCogs></FaCogs>
                    </div>
               <h3 className='skill-h3'>Front-End Skills</h3>
                  <div className='all-skills'>
                     
                      <div className='single-skill'>
                     <div className='skill-icon'>
                      <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>HTML</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>CSS</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>JavaScript</p>
                     </div>

                      <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>React JS</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Responsive Design</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Bootstrap</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>jQuery</p>
                     </div>

                      <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Testing & Debugging</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Search Engine Optimization</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Web Performance Optimization</p>
                     </div>

                  </div>

          </article>
          <article className='skill-category'>
            <div className='skill-top-icon'>
                      <FaGithub></FaGithub>
                    </div>
               <h3 className='skill-h3'>Git & Github Skills</h3>
               <div className='all-skills'>

                <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Git installation</p>
                     </div>
                     
                      <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Branching</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Merging</p>
                     </div>
                     
                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Working with remote repositories</p>
                     </div>


                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Fetching</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Pulling</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Collaboration</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Diffing</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Stashing</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Undoing Changes</p>
                     </div>

                  </div>

          </article>
          <article className='skill-category'>
            <div className='skill-top-icon'>
                      <FaUserTie></FaUserTie>
                    </div>
             <h3 className='skill-h3'>Professional Skills</h3>
             <div className='all-skills'>
                     
                      <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Problem-Solving Skills</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Interpersonal Skills</p>
                     </div>

                      <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Communication Skills</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Leadership Skills.</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Time Management Skills.</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Team Building Skills.</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Project Management Skills.</p>
                     </div>

                      

                    

                  </div>

          </article>
        
        
      </div>
    </section>
    <Qualifications></Qualifications>
    </>
  )
}

export default Skills