import React,{useState} from 'react';
import {FaGraduationCap, FaBriefcase,FaCalendarAlt} from 'react-icons/fa';

const Qualifications = () => {
            let [educState, setEducState] = useState(true);
            let [expState, setExpState] = useState(false);


let eduFunc =()=>{
setExpState(false);
setEducState(true)

}

let expFunc =()=>{
setExpState(true);
setEducState(false);
}


  return (
    <section className='qualifications-section'>
        <h2 className='section-title'>Qualifications</h2>
        <span className='section-subtitle qualifications-subtitle'>My Journey</span>
        <div className='qualification-container container'>
            <div className='qualifications-tabs'>
                  <div className={`${educState? 'qualification-btn btn--flex qualification-active': 'qualification-btn btn--flex'}`} onClick={eduFunc}>
                             <FaGraduationCap></FaGraduationCap>  
                              <p>Education</p>
                  </div>

                  <div className={`${expState? 'qualification-btn btn--flex qualification-active': 'qualification-btn btn--flex'}`} onClick={expFunc}>
                          <FaBriefcase></FaBriefcase>   
                             <p> Experience</p>
                  </div>
            </div>

            <div className='qualification-sections'>
                <div className={`${educState?'qualification-content qualification-content-active': 'qualification-content'}`}  >
                    <div className='qualification-data'>
                        <div  >
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Bachelor of Science in Telecommunications Engineering</h3>
                            <span className='qualification-subtitle'>Kampala International University</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2010 - 2014</p>
                            </div>
                        </div>
                        
                       
                     
                    </div>
                    
                    <div className='qualification-data'>
                        
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>


                        <div>
                            <h3 className='qualification-title'>Uganda Advanced Certificate of Education</h3>
                            <span className='qualification-subtitle'>Lango College</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2008 - 2009</p>
                            </div>
                        </div>
                        
                        
                    </div>

                    <div className='qualification-data'>
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        
                        <div>
                            <h3 className='qualification-title'>Uganda Certificate of Education</h3>
                            <span className='qualification-subtitle'>Almond College</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2004 - 2007</p>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className={`${expState?'qualification-content exp qualification-content-active': 'exp qualification-content'}`} >
                    <div className='qualification-data'>
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Bensites Uganda Limited</h3>
                            <span className='qualification-subtitle'>Front-End Engineer</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2017 - To Date</p>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className='qualification-data'>
                         
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>


                        <div>
                            <h3 className='qualification-title'>Kampala International University</h3>
                            <span className='qualification-subtitle'>Teaching Assistant</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2015 - 2022</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className='qualification-data'>
                         <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Uganda Telecom Limited</h3>
                            <span className='qualification-subtitle'>OCB Switch Engineer</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2015 - 2021</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
  
        </section>
  )
}

export default Qualifications