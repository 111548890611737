import React from 'react';
import {BsMouse} from 'react-icons/bs'


let scrollFunc =()=>{
  console.log('ben');
  window.scrollTo({
            top: 520,
            behavior: "smooth",
        });
 
}
const ScrollDown = () => {
  return (
    <div className='home-scroll'>
        <div className='home-scroll-button button--flex wheel' onClick={scrollFunc}>
            <BsMouse ></BsMouse>
            <p className='scroll-p'>Scroll Down</p>
       
        </div>
    
    </div>
  )
}

export default ScrollDown;