import React,{useState} from 'react';
import {Link} from 'react-router-dom'

const Portfolio = () => {
  let [reactProjects, setReactProjects] = useState(true);
   let [modernProjects, setModernProjects] = useState(false);
    let [responsiveProjects, setResponsiveProjects] = useState(false);

  let reactFunc =()=>{
       setReactProjects(true);
       setModernProjects(false);
       setResponsiveProjects(false);
  }

   let modernFunc =()=>{
    setReactProjects(false);
       setModernProjects(true);
       setResponsiveProjects(false);

  }

   let responsiveFunc =()=>{
setReactProjects(false);
       setModernProjects(false);
       setResponsiveProjects(true);
  }


  return (
    <section className='portfolio-section'      > 
   <h2 className='section-title'>Portfolio</h2>
      <span className='section-subtitle section-subtitle1'>My Projects</span>
      <div className='portfolio-container'>
          <div className='portfolio-btns'>
            <button className={`${reactProjects?'p-btn p-btn-active':'p-btn'}`} onClick={reactFunc}> React JS Projects</button>
             <button className={`${modernProjects?'p-btn p-btn-active':'p-btn'}`}  onClick={modernFunc}> JavaScript Projects</button>
             <button className={`${responsiveProjects?'p-btn p-btn-active':'p-btn'}`}  onClick={responsiveFunc}>Responsive Websites</button>

          </div>
           
    
          <div className={`${reactProjects?'portfolio-contents':'hide'}`}>
            
         <a href="https://benstore-website.netlify.app" className="porfolio-link" target="_blank">      <article className='porfolio-content'>
                      <img src="./images/BenStore1.jpg"></img>
                      <p>E-Commerce Website - "BenStore"</p>
               </article></a> 
       <a href="https://rak.bensitesug.com" className="porfolio-link" target="_blank">      <article className='porfolio-content'>
                      <img src="./images/rak.jpg"></img>
                      <p>Engineering Company Website</p>
               </article></a> 

       <Link to="/" className="porfolio-link" target="_blank">   <article className='porfolio-content'>
                      <img src="./images/portfolio.jpg"></img>
                      <p>Portfolio Website</p>
               </article></Link>


           <a href="https://meals.benardawuko.com" className="porfolio-link" target="_blank">      <article className='porfolio-content'>
                      <img src="./images/image1.jpg"></img>
                      <p>Meals Database App</p>
               </article></a>

<a href="https://birthday.benardawuko.com" className="porfolio-link" target="_blank">      <article className='porfolio-content'>
                      <img src="./images/bd.jpg"></img>
                      <p>Birthday Reminder App</p>
               </article></a>

          </div>


          <div className={`${modernProjects?'portfolio-contents':'hide'}`}>
       <a href="https://accordion.benardawuko.com" className="porfolio-link" target="_blank">     <article className='porfolio-content'>
                      <img src="./images/accordion.jpg"></img>
                      <p>Accordion</p>
               </article></a>
  <a href="https://countdown.benardawuko.com" className="porfolio-link" target="_blank">               <article className='porfolio-content'>
                      <img src="./images/countdown.jpg"></img>
                      <p>Countdown App</p>
               </article></a>
    <a href="https://filter.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/filter.jpg"></img>
                      <p>Filter Menu</p>
               </article></a>

<a href="https://pagination.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/pagination.jpg"></img>
                      <p>Pagination</p>
               </article></a>

               <a href="https://reviews.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/reviews.jpg"></img>
                      <p>Reviews</p>
               </article></a>

               <a href="https://sidebar.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/sidebar.jpg"></img>
                      <p>Sidebar</p>
               </article></a>
               <a href="https://submenus.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/submenus.jpg"></img>
                      <p>Sub Menus</p>
               </article></a>

                <a href="https://tabs.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/tabs.jpg"></img>
                      <p>Module Tabs</p>
               </article></a>

                <a href="https://wikipedia.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/wikipedia.jpg"></img>
                      <p>Wikipedia Search Engine</p>
               </article></a>

               <a href="https://nav.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/nav.jpg"></img>
                      <p>Responsive Navbar</p>
               </article></a>

                <a href="https://modal.benardawuko.com" className="porfolio-link" target="_blank">            <article className='porfolio-content'>
                      <img src="./images/modal.jpg"></img>
                      <p>Modal Window</p>
               </article></a>

          </div>

          <div className={`${responsiveProjects?'portfolio-contents':'hide'}`}>
       <a href="https://awesomemindspeaks.org" className="porfolio-link" target="_blank">          <article className='porfolio-content'>
                      <img src="./images/ams.jpg"></img>
                      <p>NGO Website</p>
               </article></a>
          <a href="https://https://tysultengineers.com" className="porfolio-link" target="_blank">       <article className='porfolio-content'>
                      <img src="./images/ty.jpg"></img>
                      <p>Engineering Company Website</p>
               </article></a>
         <a href="https://agrisolafrica.com/" className="porfolio-link" target="_blank">        <article className='porfolio-content'>
                      <img src="./images/agrisol.jpg"></img>
                      <p>Agribusiness Company Website</p>
               </article></a>

         <a href="https://mbhu.org" className="porfolio-link" target="_blank">        <article className='porfolio-content'>
                      <img src="./images/mbhu.jpg"></img>
                      <p>NGO Website</p>
               </article></a>

          </div>



        




      </div>

    </section>
  )
}

export default Portfolio