import React from 'react';
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import {FaGithubSquare } from "react-icons/fa";

const Social = () => {
  return (
    <div className='home-social'>
        <a href="https://ug.linkedin.com/in/benard-awuko-b20591a3" className='home-social-icon' target="_blank">
<FaLinkedin></FaLinkedin>
        </a>

        <a href="https://github.com/benard-awuko" className='home-social-icon' target="_blank">
<FaGithubSquare></FaGithubSquare>
        </a>

        <a href="https://twitter.com/benardawuko" className='home-social-icon' target="_blank">
<FaTwitterSquare></FaTwitterSquare>
        </a>

    </div>
  )
}

export default Social