import { type } from '@testing-library/user-event/dist/type';
import React, {useEffect, useState} from 'react';
import {BsColumnsGap} from 'react-icons/bs';
import {FaReact, FaRegThumbsUp, FaArrowRight, FaTimes} from 'react-icons/fa';
import {HiOutlineCode, HiOutlineCog} from 'react-icons/hi';


const Services = () => {
                      let [modal1, setModal1] = useState(false);
                      let [modal2, setModal2] = useState(false);
                      let [modal3, setModal3] = useState(false);
                      let [modal4, setModal4] = useState(false);
                      let [modal5, setModal5] = useState(false);


useEffect(()=>{
document.title = 'Services - Benard Awuko'
},[]);

let modalOneFunc =()=>{
  setModal1(true);
}

let modalTwoFunc =()=>{
  setModal2(true);
}

let modalThreeFunc =()=>{
  setModal3(true);
}

let modalFourFunc =()=>{
  setModal4(true);
}

let modalFiveFunc =()=>{
  setModal5(true);
}

let closeModalOne = ()=>{
setModal1(false);

}

let closeModalTwo = ()=>{
setModal2(false);
}

let closeModalThree = ()=>{
setModal3(false);
}

let closeModalFour = ()=>{
setModal4(false);
}

let closeModalFive = ()=>{
setModal5(false);
}

let closeAll =(e)=>{
  if(e.target.classList.contains('modal-container')){
  setModal1(false);
  setModal2(false);
  setModal3(false);
   setModal4(false);
      setModal5(false);
  }

}

window.addEventListener('keydown',(e)=>{
 
  if(e.key === 'Escape'){
    setModal1(false);
  setModal2(false);
  setModal3(false);
   setModal4(false);
      setModal5(false);
  }

})


  return (
    <section className='section-services'>
      <h2 className='section-title'>Services</h2>
      <span className='section-subtitle section-subtitle1'>What I Do</span>
      <div className='services-container'>
          <article className='single-service'>
              <div className='service-icon'>
                <BsColumnsGap></BsColumnsGap>
              </div>
            <h3 className='skill-h3 service-h3'>Responsive Web Design & Development</h3>
            <button    onClick={modalOneFunc} className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service'>
            <div className='service-icon'>
                <FaReact></FaReact>
              </div>
            <h3 className='skill-h3 service-h3'>React JS Development</h3>
             <button  onClick={modalTwoFunc}    className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service'>
            <div className='service-icon'>
                <HiOutlineCode></HiOutlineCode>
              </div>
            <h3 className='skill-h3 service-h3'>UI/UX Design & Development</h3>
             <button onClick={modalThreeFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>
          <article className='single-service'>
            <div className='service-icon'>
                <HiOutlineCog></HiOutlineCog>
              </div>
            <h3 className='skill-h3 service-h3'>Custom Web App Development</h3>
             <button onClick={modalFourFunc}    className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>

          <article className='single-service'>
            <div className='service-icon'>
                <FaRegThumbsUp></FaRegThumbsUp>
              </div>
            <h3 className='skill-h3 service-h3'>Maintenance & Support </h3>
             <button onClick={modalFiveFunc}  className='service-btn'>Read More <span>
              <FaArrowRight></FaArrowRight>
              </span></button>
          
          </article>



      </div>
{/* Modals Start */}
      <section className={`${modal1?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalOne}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Responsive Web Design & Development</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>I provide solutions by making websites and applications smart enough to resize and adapt content based on the screen size of the device. </p>
<p className='modal-p'>
My responsive web design & development builds on latest HTML5, CSS and JavaScript frameworks offering features that identify a browser resolution and CSS scripts that adapt elements according the layout of a particular device. 
</p>

          </div>

      </section>

       <section className={`${modal2?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalTwo}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>React JS Development</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>You can count on me to build a project from scratch. I craft highly flexible and dynamic React JS applications with a component based architecture to deliver interactive and intuitive interfaces. </p>
<p className='modal-p'>
My React JS applications are well optimized for niche requirements and meet industry standards.  
</p>
<p className='modal-p'>
In addition, my thoughtful designs and usability practices create experiences that benefit customers.
</p>
          </div>
      </section>

      <section className={`${modal3?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalThree}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>UI/UX Design & Development</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>I provide top-notch user-interface (UI)/user-experience (UX) development services. I emphasize an eye-catching look and feel for web applications which eventually increase user interaction. </p>
<p className='modal-p'>
I create compelling, interactive, and intuitive digital experiences that enhance customer loyalty and brand value.  
</p>

          </div>
      </section>

      <section className={`${modal4?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalFour}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Custom Web App Development</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>My web app development services are focused on meeting an organization's specific requirements and business needs. </p>
<p className='modal-p'>
I translate business needs into technical requirements, suggest a stack of tools, as well as provide time and cost estimates.
</p>
          </div>
      </section>

      <section className={`${modal5?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalFive}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Maintenance & Support </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>I provide custom and comprehensive website maintenance service that helps deliver a fast, secure, and seamless online experience. </p>
<p className='modal-p'>
I provide clients with a competitive advantage, which is necessary for thriving in today’s competitive business environment. More so, I align technology with business needs to empower an entity with seamlessly integrated, robust and scalable technology solutions. 
</p>
          </div>
      </section>


    </section>
  )
}

export default Services