import React, {useEffect} from 'react'
import Info from '../components/Info';
import {Link} from 'react-router-dom';
import {FaBookReader, FaHandshake, FaLightbulb, FaHeart, FaSearchPlus} from 'react-icons/fa'

const About = () => {
useEffect(()=>{
document.title = 'About - Benard Awuko'
},[])



  return (<section className='about section section-about'>
    <h2 className='section-title'>About Me</h2>
    <span className='section-subtitle section-subtitle1'>My Attributes</span>
    <div className='about-container '>
      <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div>
       <div className='about-data'>
       <div className='about-info '>
        <div className='about-box'>
           <div className='about-icon-cover'>
          <span className='about-icon'><FaLightbulb></FaLightbulb>  </span>
          </div>
          <h3 className='about-title'>Creativity</h3>
          <p>I develop innovative approaches and ideas. More so, I act with imagination and display original thinking and creativity.</p>
        </div>
        <div className='about-box'>
            <div className='about-icon-cover'>
          <span className='about-icon'><FaHeart></FaHeart></span>
          </div>
          <h3 className='about-title'>Passion</h3>
          <p>I’m awash with the zeal that brings life to work. I’m ever optimistic, I love what I do, I’m self-driven and willing to endure hardship for the sake of what I care about.</p>
        </div>
        <div className='about-box'>
          <div className='about-icon-cover'>
          <span className='about-icon'><FaSearchPlus></FaSearchPlus></span>
          </div>
          <h3 className='about-title'>Attention to detail</h3>
          <p>I focus on all areas of a project or task, no matter the size. More so, I’m thorough in reviewing my work. And so, my works are generally meticulous.</p>
        </div>
        <div className='about-box'>
          <div className='about-icon-cover'>
          <span className='about-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='about-title'>Integrity</h3>
          <p>I’m intrinsically motivated to do what is right. I make ethical decisions and address challenges calmly and purposefully. </p>
        </div>
    
    </div>
        <Link to="cv" className='button button--flex hello-btn'>
         My Resume<span className='hello-icon'><FaBookReader></FaBookReader></span>
         </Link>


        </div>


    </div>



  </section>

  )
  
}

export default About