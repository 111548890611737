import React from 'react';
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import {FaGithubSquare } from "react-icons/fa";


const Footer = () => {
  return (
    <footer className='footer'>
        <div className='footer-center'>
             <div className='footer-icons'>
                  <span className='footer-icon'>
                  <a href='https://ug.linkedin.com/in/benard-awuko-b20591a3' className='footer-social-link' target='_blank'>  <FaLinkedin></FaLinkedin></a>
                  </span>
                    <span className='footer-icon'>
                <a href='https://github.com/benard-awuko' className='footer-social-link' target='_blank'>     <FaGithubSquare></FaGithubSquare></a>
                  </span>
                  <span className='footer-icon'>
                 <a href='https://twitter.com/benardawuko' className='footer-social-link' target='_blank'>   <FaTwitterSquare></FaTwitterSquare></a>
                  </span>
         
                




              </div>
            <p className="footer-p">
        &copy; {new Date().getFullYear()} <span className="date"></span> Portfolio Website | Designed {`&`} Developed By <a href="https://ug.linkedin.com/in/benard-awuko-b20591a3" target="_blank" className="footer-link" rel="noopener noreferrer">Benard Awuko</a>
        
      </p>


        </div>
    </footer>
  )
}

export default Footer