import React from 'react';

// import Caleps from './';
// import Alfie from '../Alfie.jpg';
// import Sam from '../Sam.jpg';




let Reviewers = [
{id:1,
image:'./images/Caleps.jpg',
name: 'Caleps Labeja',
job: 'Electrical Engineer',
text: 'In need of quality, innovation & value for money? Choose Benard Awuko!'

},
{
id:2,
image: './images/Alfie.jpg',
name: 'Alfred Ochola',
job: 'Software Engineer',
text: 'Benard Awuko is known for stunning, dynamic & responsive websites.'
},
{
id:3,
image: './images/Sam.jpg',
name: 'Samuel Kizza',
job: 'Data Warehouse Consultant',
text: 'Without doubt, Benard Awuko delivers with quality & reliability.'

}
];

export default Reviewers;
